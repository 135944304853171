
import Button from '@/components/Button.vue'
import { showAlert } from '@/components/popup/popup'
import TermsAndPolicy from '@/components/TermsAndPolicy.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import { translate } from '@/i18n'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import {
  registerFormSchema,
  useEmailRegisterUI,
  useRegister,
  useRegisterCode,
} from '@/pages/register/register'
import Form from 'common/form/Form.vue'
import { defineComponent, ref, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheSignup',
  components: { TermsAndPolicy, GoogleLoginBtn, TheCodeInput, ThePasswordInput, TheMobileInput, Button, Form },

  setup () {
    const [register, progress] = useRegister()
    const [sendCode] = useRegisterCode()
    const form = ref<HTMLFormElement | null>(null)
    const { useEmail, prepareUseEmail, cancelUseEmail } = useEmailRegisterUI()
    const islamicCheck = shallowRef(false)
    const isIslamic = shallowRef(false)
    const countryCode = shallowRef()

    const ccChange = (code: string) => {
      countryCode.value = code
      if (Number(code) === 62 || Number(code) === 90) {
        isIslamic.value = true
      } else {
        isIslamic.value = false
      }
    }

    const submitClick = () => {
      if (form.value) {
        form.value.values.islamFlag = Number(islamicCheck.value)
      }
      (form.value as HTMLFormElement).submit()
    }

    return {
      form,
      register,
      progress,
      registerFormSchema,
      useEmail,
      islamicCheck,
      isIslamic,
      countryCode,
      ccChange,
      sendCode (cc: string, mobile: string, cb: () => void) {
        sendCode(cc, mobile).then(() => {
          showAlert(translate('toast_9', 'Code has been sent'))
          prepareUseEmail()
          cb()
        })
      },
      onCodeInput: cancelUseEmail,
      submitClick,
    }
  },
})
