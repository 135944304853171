<template>
<PageWithHeader class="register px-16">
  <template #title>
    <t path="">WeTrade</t>
  </template>
  <template #right>
    <router-link to="/service">
      <Pic src="login/customer_service" width="24" height="24" />
    </router-link>
  </template>
  <div class="fill df-col df-between">
    <div class="tab-wrap d-f">
      <div :class="['tab-item', {active:actIndex===0}]" @click="switchTab(0)">
        <t path="signinup_2"></t>
      </div>
      <div :class="['tab-item', {active:actIndex===1}]" @click="switchTab(1)">
        <t path="signinup_3"></t>
      </div>
      <div :class="['s-line left',{'right': actIndex===1}]"></div>
    </div>
    <div class="flex-1">
      <TheSignup v-if="actIndex === 0" />
      <TheSignin v-if="actIndex === 1" />
    </div>
  </div>
</PageWithHeader>
</template>

<script lang="ts">
import { rotate } from '@/modules/app/app'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import TheSignin from '@/pages/login/Signin.vue'
import TheSignup from '@/pages/register/Register.vue'
import { useRouter } from 'vue-router'
import Pic from '@/components/Pic.vue'
import { callService } from '@/common/jsBridge.api'

export default defineComponent({
  name: 'Register',
  components: { PageWithHeader, TheSignin, TheSignup, Pic },

  setup () {
    const router = useRouter()
    const actIndex = shallowRef(1)
    const switchTab = (index: number) => {
      actIndex.value = index
    }

    rotate('vertical')

    actIndex.value = +router.currentRoute.value.params.index || 0

    return {
      actIndex,
      switchTab,
      callService,
    }
  },
})
</script>

<style scoped lang="scss">
.tab-wrap {
  height: 48px;
  position: relative;

  .tab-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: var(--color-tip);

    &.active {
      color: var(--color-primary);
    }
  }

  .s-line {
    width: 48px;
    height: 2px;
    background-color: var(--color-primary);
    position: absolute;
    bottom: 0;
    transition: 0.5s;

    &.left {
      left: 25%;
      margin-left: -24px;
    }

    &.right {
      left: 75%;
      margin-left: -24px;
    }
  }
}
</style>
