/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   Register.vue of WeTrade
*/
<template>
<div class="px-16 fill df-col df-between">
  <Form class="form" ref="form" :schema="registerFormSchema" @submit="register" #="{ values }">
    <TheMobileInput @ccChange="ccChange"/>
    <TheCodeInput @send="cb => sendCode(values.countryCode, values.mobile, cb)"
      @keydown="onCodeInput" />
    <p v-if="useEmail" class="f-sm pt-8">
      <t path="signinup_22" class="c-title">OTP not received?</t>
      <router-link :to="`/signup/email?countryCode=${countryCode}${isIslamic?'&isIslamic='+isIslamic:''}`" class="c-primary ml-4">
        <t path="signinup_23"></t>
      </router-link>
    </p>
    <ThePasswordInput />
    <p class="f-xs c-title mt-8">*
      <t path="signinup_9">Password must contain at least 6 characters</t>
    </p>
  </Form>
  <div class="btns-wrap" style="padding-bottom: 40px">
    <div v-if="isIslamic" class="islamic mb-16 df-middle" @click="() => islamicCheck = !islamicCheck">
      <div class="checkbox br-sm mr-8" :class="{'active': islamicCheck}"></div>
      <t path="signinup_24" class="f-md">Islamic Account  (Swap Free)</t>
    </div>
    <t #="{td, t}" custom>
      <p class="f-sm term">
        {{t('signinup_10')}}
        <TermsAndPolicy />
      </p>
    </t>
    <Button class="block primary mt-24 f-lg f-bold br-lg" @click="submitClick" :progress="progress">
      <t path="signinup_14" #="{td}" custom>{{td || 'Sign up'}}</t>
    </Button>
    <GoogleLoginBtn class="br-lg" :islamFlag="islamicCheck" />
  </div>
</div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { showAlert } from '@/components/popup/popup'
import TermsAndPolicy from '@/components/TermsAndPolicy.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import { translate } from '@/i18n'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import {
  registerFormSchema,
  useEmailRegisterUI,
  useRegister,
  useRegisterCode,
} from '@/pages/register/register'
import Form from 'common/form/Form.vue'
import { defineComponent, ref, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheSignup',
  components: { TermsAndPolicy, GoogleLoginBtn, TheCodeInput, ThePasswordInput, TheMobileInput, Button, Form },

  setup () {
    const [register, progress] = useRegister()
    const [sendCode] = useRegisterCode()
    const form = ref<HTMLFormElement | null>(null)
    const { useEmail, prepareUseEmail, cancelUseEmail } = useEmailRegisterUI()
    const islamicCheck = shallowRef(false)
    const isIslamic = shallowRef(false)
    const countryCode = shallowRef()

    const ccChange = (code: string) => {
      countryCode.value = code
      if (Number(code) === 62 || Number(code) === 90) {
        isIslamic.value = true
      } else {
        isIslamic.value = false
      }
    }

    const submitClick = () => {
      if (form.value) {
        form.value.values.islamFlag = Number(islamicCheck.value)
      }
      (form.value as HTMLFormElement).submit()
    }

    return {
      form,
      register,
      progress,
      registerFormSchema,
      useEmail,
      islamicCheck,
      isIslamic,
      countryCode,
      ccChange,
      sendCode (cc: string, mobile: string, cb: () => void) {
        sendCode(cc, mobile).then(() => {
          showAlert(translate('toast_9', 'Code has been sent'))
          prepareUseEmail()
          cb()
        })
      },
      onCodeInput: cancelUseEmail,
      submitClick,
    }
  },
})
</script>

<style scoped lang="scss">
.term {
  color: var(--color-title);
}

.form {
  margin-top: 40px;
}
.checkbox{
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-border);
  &.active{
    background-color: var(--color-primary);
    border: none;
    position: relative;
  }
  &.active::after{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(/img/login/check@2x.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    content: '';
  }
}
</style>
