/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/1
 * @description
 *   register.ts of WeTrade
 */
import useRequest from '@/hooks/useRequest'
import { withDeviceIdentities } from '@/modules/app/app'
import { login } from '@/modules/user/user'
import { User } from '@/modules/user/user.api'
import { emailLoginSchema, loginFormSchema } from '@/pages/login/login'
import { registerWithEmail, requestCode, requestRegister } from '@/pages/register/register.api'
import state from '@/state'
import { onBeforeUnmount, shallowRef } from 'vue'
import { useRouter } from 'vue-router'

export const registerFormSchema = {
  ...loginFormSchema,
  code: {
    pattern: /^\d{4}$/,
  },
}

export const emailRegisterSchema = emailLoginSchema

export const useRegister = (withEmail = false) => {
  const [commit, progress] = useRequest(withEmail ? registerWithEmail : requestRegister)
  const router = useRouter()

  const onRegister = (user?: User) => {
    if (user) {
      login(user)
      router.replace('/')
    }
  }

  const register = (data: Data) => withDeviceIdentities((ids: Data) => {
    return commit({
      ...data,
      ...ids,
      versionFlag: state.group === 'B',
    }).then(onRegister)
  })

  return [register, progress]
}

export const useRegisterCode = () => useRequest(requestCode)

export const useEmailRegisterUI = () => {
  const useEmail = shallowRef(false)
  let timer = 0

  onBeforeUnmount(() => {
    clearTimeout(timer)
  })

  const prepareUseEmail = () => timer = window.setTimeout(() => {
    useEmail.value = true
  }, 1000 * 40)

  const cancelUseEmail = () => {
    clearTimeout(timer)
    useEmail.value = false
  }

  return {
    useEmail,
    prepareUseEmail,
    cancelUseEmail,
  }
}
