
import { rotate } from '@/modules/app/app'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import TheSignin from '@/pages/login/Signin.vue'
import TheSignup from '@/pages/register/Register.vue'
import { useRouter } from 'vue-router'
import Pic from '@/components/Pic.vue'
import { callService } from '@/common/jsBridge.api'

export default defineComponent({
  name: 'Register',
  components: { PageWithHeader, TheSignin, TheSignup, Pic },

  setup () {
    const router = useRouter()
    const actIndex = shallowRef(1)
    const switchTab = (index: number) => {
      actIndex.value = index
    }

    rotate('vertical')

    actIndex.value = +router.currentRoute.value.params.index || 0

    return {
      actIndex,
      switchTab,
      callService,
    }
  },
})
