/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Login.vue of WeTrade
*/
<template>
<div class="login fill px-16 df-col df-between">
  <Form ref="form" class="form" :schema="loginFormSchema" @submit="login">
    <TheMobileInput  @ccChange="ccChange"/>
    <ThePasswordInput />
    <p class="c-primary t-right f-medium f-sm mt-12">
      <router-link to="/reset">
        <t path="signinup_18">Forgot Password?</t>
      </router-link>
    </p>
    <p class="f-sm c-title mt-24 t-right">
      <Pic src="login/mail" type="svg" with="16" height="16" class="d-ib" />
      <router-link :to="`/login/email?countryCode=${countryCode}`">
        <t path="signinup_21" class="ml-4">Sign In with Email</t>
      </router-link>
    </p>
  </Form>
  <div class="btns-wrap" style="padding-bottom: 40px">
    <Button class="primary f-bold f-lg block br-lg" @click="submit" :progress="progress">
      <t path="signinup_19" #="{td}" custom>{{td || 'Sign in'}}</t>
    </Button>
    <GoogleLoginBtn class="br-lg" />
  </div>
</div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { loginFormSchema, useLogin } from '@/pages/login/login'
import { roam } from '@/state/actions'
import Form from 'common/form/Form.vue'
import { defineComponent, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TheSignin',
  components: { Pic, GoogleLoginBtn, ThePasswordInput, TheMobileInput, Button, Form },
  setup () {
    const { login, progress } = useLogin()
    const router = useRouter()
    const form = ref<HTMLFormElement | null>(null)
    const countryCode = shallowRef()

    const ccChange = (code: string) => {
      countryCode.value = code
    }

    const tryNow = () => {
      roam()
      router.push('/')
    }

    onMounted(() => {
      window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
          (form.value as HTMLFormElement).submit()
        }
      })
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', () => {
        // remove listener
      })
    })

    return {
      form,
      roam: tryNow,
      login,
      progress,
      loginFormSchema,
      countryCode,
      ccChange,
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
</script>

<style scoped lang="scss">
.form {
  margin-top: 40px;
}
</style>
